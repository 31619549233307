import React, { Component } from 'react';
import  ReactDom from 'react-dom';
import { HashRouter, Route } from 'react-router-dom'
import { createHashHistory } from "history";
import { Menu, Row, Col } from 'antd';
const SubMenu = Menu.SubMenu;
import CarouselPage from './pages/carouselPage'
import Home from './pages/home'
import Productions from './pages/productions'
import AboutUs from './pages/aboutUs'
import CorporationCase from './pages/CorporationCase'
import ConcatUs from './pages/ConcatUs'
import ProductMessage from './pages/ProductMessage'
import SchemeMessage from './pages/SchemeMessage'
import SoftCenter from './pages/SoftCenter'

import logo from './images/logo.png';
import batb from './images/batb.png';
import './less/index.less';
import 'antd/dist/antd.less';
import "slick-carousel/slick/slick.less";
import "slick-carousel/slick/slick-theme.less";
import title_bg from './images/product_center.png';
import img1 from './images/gz.jpg'
import Ajax from './utils/Ajax';

import p1 from './images/p1.png';
import cus from './images/cus.png';

var channel = new MessageChannel();

var port1 = channel.port1;
export var port2 = channel.port2;

const customHistory = createHashHistory();

class App extends Component {
    constructor(props) {
		super(props)
		this.state = {
           // headList: ['首页','产品介绍','公司案例','关于我们'],
            headList: [ {
                label: '首页',
                url: '/'
            },{
                label: '产品推荐',
                url: '/productMessage'
            },{
                label: '购买入口',
                url: 'https://wumobg.tmall.com/',
            },{
                label: '开放平台',
                url: 'http://open.lingmoyun.com/#/home',
            },{
                label: '联系我们',
                url: '/concatUs',
            }],
            current:'/',
            productInfo: {},
            list:[],
            productList:[],
            schemeList:[],
            imgs: {
                cus: cus,
                p1: p1,
                title_bg: title_bg
            },
            bgImgName: 'title_bg'
        }
    }
    
    componentDidMount(){
        let t = this;
        this.changeCurrent();
        let date = new Date('2022-12-07').getTime();
        let now = Date.now();
       if ((date - now) > 0) {
        let styles = window.getComputedStyle(document.body, null);
        if (this.props.history.location.pathname == '/') {
            document.body.style.filter = 'grayscale(1.0)';
        } else {
            delete styles.filter;
            document.body.style = styles; 
        }
        window.addEventListener('hashchange', () => {
            console.log(window.location);
            let styles = window.getComputedStyle(document.body, null);
            if (this.props.history.location.pathname == '/' && window.location.hash == '#/') {
                document.body.style.filter = 'grayscale(1.0)';
            } else {
                delete styles.filter;
                document.body.style = styles; 
            }
        })
       } else {
            let styles = window.getComputedStyle(document.body, null);
            delete styles.filter;
            document.body.style = styles; 
       }

        let productInfo = localStorage.getItem('productInfo')
            ? JSON.parse(localStorage.getItem('productInfo'))
            : {};
        this.setState({
            productInfo
        });
        port1.onmessage = function(event) {
            t.setState({
                current:event.data
            })
        }

        Ajax('sjy/equipment/officialwebsite/getMainPageInfo')
            .then((res) => {
                console.log(res);
                this.setState({
                    list:res.rows,
                    productList:res.data,
                    schemeList:res.data2
                })
            })
    }

    changeCurrent(){
        let loc = this.props.history.location.pathname;

        let current = '/';
        this.state.headList.slice(1,).map((item,index) => {
            if(loc.includes(item.url)){
                current = item.url;
            }
        })
    
        this.setState({
            current
        })
    }

    navigate = (e) => {
        try {
            let loc = JSON.parse(e.key);

            localStorage.setItem('productInfo', JSON.stringify(loc.state));
            
            this.setState({
                productInfo: loc.state,
                current: e.key
            },() => {
                this.props.history.push(loc);  
            });
              
        } catch (error) {
            if (
                e.key.includes('http://') ||
                e.key.includes('https://')
            ) {
                window.open(e.key);
                return;
            }
          
            this.setState({
                current: e.key,
            })
            this.props.history.push(e.key);    
        }
      
     
    }

    //切换图片
    changeImage(imgName) {
        this.setState({
            bgImgName: imgName 
        });
    }

    //
    changeBackground() {
       return this.props.history.location.pathname == '/concatUs'
        ? '#c4c5c8'
        : '#fff'
    }

    render() {
        const { headList,current, list, productList, schemeList } = this.state;
        //不同域名下不同序号
        const recordNumberHrefs = [
            "33010802011569",
            "33010802011570",
            "33010802011560"
        ];
        let hostNumber = window.location.host === "www.lingmocloud.com" 
            ? 1 
            : window.location.host === "www.lingmoyun.cn" 
            ? 2 
            : 3;

        return (
            <div>
                <div className="header">
                    <img src={logo} className="headerImg" onClick={() => this.setState({current:'0'},() => this.props.history.push('/'))}/>
                    <Menu onClick={this.navigate} selectedKeys={[current]} mode="horizontal">
                        {
                            headList.map((item,index) => {
                                if (item.label == '产品推荐') {
                                    return <SubMenu title={item.label}>
                                        {
                                            productList.map((item1, i) => {
                                                return <Menu.Item key={JSON.stringify({pathname:item.url, state: item1})}>{item1.name}</Menu.Item>
                                            })
                                        }
                                  </SubMenu>
                                } else {
                                    return <Menu.Item key={item.url}>
                                        { item.label }
                                    </Menu.Item>
                                }
                            })
                        }
                    </Menu>
                </div>
                {
                    current == '/'
                        ? <CarouselPage list={ list }/>
                        : <div style={{position:'relative',textAlign:'center'}}>
                            <img src={this.state.imgs[this.state.bgImgName]}/>
                            <h1 style={{position:'absolute',top: '50%',width: '100%',textAlign: 'center',fontSize: '36px',color: '#fff'}}>{this.state.headList[current]}</h1>
                        </div>
                }
                
                <div>
                    <HashRouter>
                        <div style={{background: this.changeBackground()}}>
                            <Route path='/' exact render={
                                (args) => <Home {...args} productList={ productList } schemeList={ schemeList }/>
                            }/>
                            <div className="content">
                                <Route path='/productions' component={Productions}/>
                                <Route path='/aboutUs' component={AboutUs}/>
                                <Route path='/corporationCase' component={CorporationCase}/>
                                <Route path='/concatUs' render={
                                    (args) => <ConcatUs {...args} changeImage={this.changeImage.bind(this)}/>
                                }/>
                                <Route path='/productMessage' render={
                                    (args) => <ProductMessage {...args} data={this.state.productInfo} changeImage={this.changeImage.bind(this)}/>
                                }/>
                                <Route path='/SoftCenter' component={SoftCenter}/>
                                <Route path='/schemeMessage' component={SchemeMessage}/>
                            </div>
                        
                        </div>
                    </HashRouter>
                </div>
                <div className="footer">
                    <div className="footer2">
                        <div className='footer2-msg'>
                            <Row className='menu-list'>
                                <Col span={12}>
                                    <p className='menu-list-title'>软件产品</p>
                                    <ul>
                                        <li style={{cursor:'pointer'}} onClick={() => {this.props.history.push('/productions');this.changeCurrent()}}>家印宝app</li>
                                        <li style={{cursor:'pointer'}} onClick={() => {this.props.history.push('/productions');this.changeCurrent()}}>家印宝小程序</li>
                                        <li style={{cursor:'pointer'}} onClick={() => {this.props.history.push('/productions');this.changeCurrent()}}>打票猿小程序</li>
                                        <li style={{cursor:'pointer'}} onClick={() => {this.props.history.push('/productions');this.changeCurrent()}}>DAPRT用户配置工具</li>
                                        <li style={{cursor:'pointer'}} onClick={() => {this.props.history.push('/productions');this.changeCurrent()}}>零墨云配置工具</li>
                                        <li style={{cursor:'pointer'}} onClick={() => {this.props.history.push('/productions');this.changeCurrent()}}>零墨云管家小程序</li>
                                        <li style={{cursor:'pointer'}} onClick={() => {this.props.history.push('/productions');this.changeCurrent()}}>零墨云开放平台</li>
                                    </ul>
                                </Col>
                                <Col span={12}>
                                    <p className='menu-list-title'>关于我们</p>
                                    <ul>
                                        <li>联系电话</li>
                                        <li>0571-85083306</li>
                                        <li></li>
                                        <li>技术支持</li>
                                        <li>service@lingmoyun.com</li>
                                        <li></li>
                                        <li>地址</li>
                                        <li>杭州市滨江区网商路271号 尚坤中控数字园</li>
                                    </ul>
                                </Col>
                            </Row>
                            <div className='company-msg'>
                                <p className='menu-list-title'>公众号</p>
                                <div>
                                    <img src={img1} alt="" />
                                </div>
                                <div>
                              
                                    {/* <img src={img1} />
                                    请扫描二维码<br />
                                    关注我们的微信公众平台 */}
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="footer1">
                    <a target="_blank" href={"http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=" + recordNumberHrefs[hostNumber - 1]}><img style={{marginRight:"5px"}} src={batb}/>浙公网安备 {recordNumberHrefs[hostNumber - 1]}号</a>&nbsp;<a href="https://beian.miit.gov.cn" target="_blank">浙ICP备2021008802号-{hostNumber}</a>&copy;2021 杭州零墨云科技 All Rights Reserved.
                    </div>
                </div>
            </div>
            
        )
    }
}

ReactDom.render(<App history={customHistory}/>, document.getElementById('app'))
